import React, { useRef, useEffect, useState } from "react";
import { Html5Qrcode } from "html5-qrcode";
import { Input } from "../forms";
import Icon from "../icons";
import { Typography, Button } from "../utils";
import QS from "../../utils/queryStringRetrieverFromURL";
import Accordion from "../accordion";
import * as styles from "./styles.module.scss";

const nativeQR = () => {
  const [keycard, setKeycard] = useState("");
  const [loading, setLoading] = useState(false);
  const [qrMessage, setQrMessage] = useState("");
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const ios = "PLATFORM_IOS_WEBVIEW";
  const andr = "PLATFORM_ANDROID_WEBVIEW";

  const qr = useRef(null);
  const message = useRef(null);

  const takeQR = () => {
    cleanFields();
    skGetUserQRRequest(getPlatformType());
  };

  const cleanFields = () => {
    qr.current.innerHTML = "";
  };

  const showMessage = (m) => {
    setQrMessage(m);
  };

  const getPlatformType = () => {
    const lte9 = /constructor/i.test(window.HTMLElement);
    const idb = !!window.indexedDB;

    if (typeof window.JSInterface === "object") {
      return andr;
    } else if (
      (window.webkit && window.webkit.messageHandlers) ||
      !lte9 ||
      idb
    ) {
      return ios;
    } else {
      return false;
    }
  };

  const skGetUserQRRequest = (platformType) => {
    if (platformType === andr) {
      window.JSInterface.skGetUserQR();
    } else if (
      platformType === ios &&
      window.webkit &&
      window.webkit.messageHandlers
    ) {
      window.webkit.messageHandlers.skGetUserQR.postMessage("");
    } else {
      setLoading(true);
      getCameras();
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    if (value) setKeycard(value);
  };

  useEffect(() => {
    window.addEventListener("qrCodeScanned", qrCodeScanned);
    window.addEventListener("qrCodeMessage", qrCodeMessage);
    return () => {
      window.removeEventListener("qrCodeScanned", qrCodeScanned);
      window.removeEventListener("qrCodeMessage", qrCodeMessage);
    };
  }, []);

  useEffect(() => {
    if (error === true) {
      setTimeout(() => {
        setError(false);
      }, 5000);
    }
  }, [error]);

  const qrCodeScanned = (scannedValue = null) => {
    const value = qr.current.innerHTML || scannedValue;
    const { tar } = QS(value);
    if (tar) setKeycard(tar);
  };

  const qrCodeMessage = () => {
    const value = message.current.innerHTML;
    setQrMessage(value);
  };

  const getCameras = () => {
    Html5Qrcode.getCameras()
      .then((devices) => {
        if (devices && devices.length) {
          const html5QrCode = new Html5Qrcode("reader");
          const qrCodeSuccessCallback = (decodedText, decodedResult) => {
            html5QrCode.stop().then((ignore) => {
              setLoading(false);
              qrCodeScanned(decodedText);
            });
          };
          const qrCodeErrorCallback = (error) => {
            return null;
          };
          const config = { fps: 10, qrbox: { width: 300, height: 300 } };

          html5QrCode.start(
            { facingMode: "environment" },
            config,
            qrCodeSuccessCallback,
            qrCodeErrorCallback
          );
        }
      })
      .catch((err) => {
        console.log("err", err);
        setError(true);
        setErrorMsg("Permission denied or no devices found");
        setLoading(false);
      });
  };

  return (
    <div className={styles.qrScanner}>
      <Input
        label={"Keycard number"}
        icon="GuestCard"
        required
        name="keycard"
        type="text"
        onChange={(e) => handleChange(e)}
        value={keycard}
        //errorMessage={qrMessage}
      />
      <Button
        id="qr"
        onClick={() => takeQR()}
        className={styles.qrButton}
        contentClassName={styles.qrContentButton}
        disabled={loading}
      >
        <Icon icon="QrScanner" size="S" />
        <Typography variant="h300-medium" component="span">
          Read keycard number from QR code
        </Typography>
      </Button>
      <Accordion open={error}>
        <div className={styles.error}>
          <Icon icon="AlertCircle" size="S" />
          <Typography variant="h300-medium" component="span">
            {errorMsg}
          </Typography>
        </div>
      </Accordion>
      <div
        id="reader"
        style={{
          maxWidth: "300px",
          maxHeight: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
      <div style={{ display: "none" }}>
        <span id="displayQR" ref={qr}>
          {" "}
        </span>
        <span id="displayQRMessage" ref={message}>
          {" "}
        </span>
      </div>
    </div>
  );
};

export default nativeQR;
