import React, { useRef, useState, useEffect } from "react";

import * as styles from "./styles.module.scss";

const Accordion = ({ open, children, className, responsive = false }) => {
  const content = useRef(null);
  const [accordionMaxHeight, setAccordionMaxHeight] = useState(
    open ? "undefined" : "0px"
  );

  useEffect(() => {
    if (!responsive) {
      setAccordionMaxHeight(
        open ? `${content.current && content.current.scrollHeight}px` : "0px"
      );
    } else {
      setAccordionMaxHeight(open ? `` : "0px");
    }
    // eslint-disable-next-line
  }, [open]);

  return (
    <div
      ref={content}
      style={{ maxHeight: accordionMaxHeight }}
      className={`${styles.accordion} ${className || ""} ${
        responsive && styles.responsive
      } ${responsive && !open && styles.collapsed}`}
    >
      {children}
    </div>
  );
};

export default Accordion;
