import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";

import QrScanner from "../components/qrScanner";

const MockPage = () => {
  /*if (typeof window === "undefined" || typeof document === "undefined") {
    return null;
  }

  window.location.href = `${window.location.protocol}//${window.location.host}`;*/

  const Wrapper = styled.div`
    background: #fff;
    padding: 1.6rem;
    border-radius: 16px;
    width: 1200px;
    max-width: 100%;
    margin: 32px auto;
  `;

  return (
    <Layout backgroundColor={"#F4F4FA"}>
      <Wrapper>
        <QrScanner />
      </Wrapper>
    </Layout>
  );
};

export default MockPage;
